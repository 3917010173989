import React, { useState, useEffect, useCallback } from 'react';
import './App.css';
// 导入 qrcode.react 库
import { QRCodeSVG } from 'qrcode.react';
import { formatDistanceToNow, parseISO } from 'date-fns';
import { zhCN } from 'date-fns/locale';

const API_BASE_URL = 'https://api.comm.jicangjia.com/games';
//const API_BASE_URL = 'http://127.0.0.1:8000/games';

const getTimeDifference = (updateTime) => {
  try {
    const date = parseISO(updateTime.replace(' ', 'T'));
    return formatDistanceToNow(date, { addSuffix: true, locale: zhCN });
  } catch (error) {
    console.error('Invalid date:', updateTime, error);
    return '未知时间';
  }
};

const parseLink = async (link) => {
  try {
    // 检查是否包含特定字符串
    if (link.includes('http://www.battleofballs.com?')) {
      // 直接处理特殊格式的链接
      let processedLink = link
        .replace('http://www.battleofballs.com?', '')  // 移除特定前缀
         .replace('&20=&', '&20=temp&');  // 替换 &20=& 为 &20=temp&
      
      // base64 编码
      const encodedLink = btoa(processedLink);
      
      // 生成 appLink
      const appLink = `qiuqiudazuozhan://?addGame@${encodedLink}`;
      
      // 直接使用原始链接作为二维码内容
      const qrCodeContent = link.replace('@', '');
      
      console.log('Generated QR code content:', qrCodeContent);
      return {
        appLink: appLink,
        qrCodeContent: qrCodeContent
      };
    } else {
      // 原有的解析逻辑保持不变
      const proxyUrl = `http://apis.jicangjia.com/me/geturl.php?act=unshort&url=${encodeURIComponent(link)}`;
      const response = await fetch(proxyUrl);
      const longUrl = await response.text();
      const url = new URL(longUrl);
      const bParam = url.searchParams.get('b');
      if (bParam) {
        const decodedBParam = atob(bParam);
        // 提取 @@ 之间的内容
        const match = decodedBParam.match(/@@(.*?)@@/);
        console.log('match:', match);
        if (match) {
          const innerBase64 = match[1];
          const decodedInner = atob(innerBase64);
          // 提取 addGame 参数的值
          const addGameMatch = decodedInner.match(/addGame=(.*?)(&|$)/);
     
          if (addGameMatch) {
            const decodedParam = atob(decodeURIComponent(addGameMatch[1]));
            const combinedUrl = `http://www.battleofballs.com?${decodedParam}`;
            const newBParam = btoa(combinedUrl);
            console.log('Generated QR code content:', combinedUrl);
            return {
              appLink: `qiuqiudazuozhan://?addGame@${newBParam}`,
              qrCodeContent: combinedUrl
            };
          }
        }
      }
    }
  } catch (error) {
    console.error('解析链接时出错:', error);
  }
  return null;
};

// 修改 getContactAndOrderInfo 函数为异步函数
const getContactAndOrderInfo = async () => {
  try {
    const response = await fetch('/config/contacts.json');
    const contactsConfig = await response.json();
    
    const hostname = window.location.hostname;
    const subdomain = hostname.split('.')[0];
    
    // 检查是否是 IP 地址
    if (/^\d+\.\d+\.\d+\.\d+$/.test(hostname)) {
      return contactsConfig.default;
    }
    
    // 根据二级域名获取配置
    if (contactsConfig[subdomain]) {
      return contactsConfig[subdomain];
    }
    
    // 默认配置
    return contactsConfig.default;
  } catch (error) {
    console.error('加载配置文件失败:', error);
    return {
      contactMethod: "cpu8900",
      orderUrl: "https://qbob.top/qiu_qiu/?cid=207&tid=3879"
    };
  }
};

function App() {
  const [user, setUser] = useState('');
  const [order, setOrder] = useState(null);
  const [loading, setLoading] = useState(false);
  const [orderMessage, setOrderMessage] = useState('');
  const [invitation, setInvitation] = useState(null);
  const [invitationMessage, setInvitationMessage] = useState('');
  const [parsedLink, setParsedLink] = useState(null);
  const [countdown, setCountdown] = useState(0);
  const [retryCount, setRetryCount] = useState(0);
  const [showHelp, setShowHelp] = useState(false);
  const [qrCodeContent, setQrCodeContent] = useState(null);
  const [contactInfo, setContactInfo] = useState(getContactAndOrderInfo());
  const [showRefund, setShowRefund] = useState(false);
  const [refundCountdown, setRefundCountdown] = useState(10);
  const [refundLoading, setRefundLoading] = useState(false);

  const getOrder = useCallback(async (userId) => {
    if (!userId) {
      setOrderMessage('请输入有效的用户ID');
      return;
    }
    
    setLoading(true);
    setOrderMessage('');
    try {
      const response = await fetch(`${API_BASE_URL}/get_user_order?user=${userId.trim()}`);
      const data = await response.json();
      if (data.code === 0 && data.data) {
        setOrder(data.data);
        localStorage.setItem('userId', userId.trim());
      } else {
        setOrder(null);
        const contact = await getContactAndOrderInfo();
        setOrderMessage(`未找到订单信息，请检查用户ID或前往下单(加我微信${contact.contactMethod})`);
      }
    } catch (error) {
      console.error('获取订单失败:', error);
      setOrderMessage('获取订单失败，请稍后再试');
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    const loadConfig = async () => {
      const config = await getContactAndOrderInfo();
      setContactInfo(config);
    };
    
    const savedUser = localStorage.getItem('userId');
    if (savedUser) {
      setUser(savedUser);
      getOrder(savedUser);
    }
    
    loadConfig();
  }, [getOrder]);

  const updateOrderCount = async (orderId, newCount) => {
    try {
      const formData = new FormData();
      formData.append('order_id', orderId);
      formData.append('new_count', newCount);

      const response = await fetch(`${API_BASE_URL}/update_order_count`, {
        method: 'POST',
        body: formData,
      });
      const data = await response.json();
      if (data.code === 0) {
        setOrder(prevOrder => ({ ...prevOrder, quantity: newCount }));
      }
    } catch (error) {
      // 错误处理可以根据需要进行调整
    }
  };

  const getInvitation = useCallback(async () => {
    if (!order) return;
    setLoading(true);
    setInvitationMessage('');
    setParsedLink(null);
    setQrCodeContent(null);
    setShowRefund(false);
    setRefundCountdown(10);
    try {
      const response = await fetch(`${API_BASE_URL}/get?task=${order.user}`);
      const data = await response.json();
      if (data.code === 0) {
        setInvitation(data.data);
        if (data.message === '成功获取新邀请') {
          // 重新获取最新的订单数据
          const orderResponse = await fetch(`${API_BASE_URL}/get_user_order?user=${order.user}`);
          const orderData = await orderResponse.json();
          if (orderData.code === 0 && orderData.data) {
            const latestOrder = orderData.data;
            const newCount = latestOrder.quantity - 1;
            await updateOrderCount(latestOrder.id, newCount);
            setOrder({ ...latestOrder, quantity: newCount });
          } else {
            console.error('Failed to fetch latest order data');
          }
        }
        const parsedData = await parseLink(data.data.link);
        console.log('Parsed data:', parsedData);
        if (parsedData) {
          setParsedLink(parsedData.appLink);
          setQrCodeContent(parsedData.qrCodeContent);
          
          const timer = setInterval(() => {
            setRefundCountdown(prev => {
              if (prev <= 1) {
                clearInterval(timer);
                setShowRefund(true);
                return 0;
              }
              return prev - 1;
            });
          }, 1000);

          return () => clearInterval(timer);
        }
        setCountdown(0);
        setRetryCount(0);
        setTimeout(() => {
          setShowRefund(true);
        }, 60000);
      } else {
        setInvitationMessage(data.message || '获取邀请链接失败');
        setCountdown(5);
        setRetryCount(prev => prev + 1);
      }
    } catch (error) {
      console.error('获取邀请链接时出错:', error);
      setInvitationMessage('获取邀请链接失败，请稍后再试');
      setCountdown(5);
      setRetryCount(prev => prev + 1);
    } finally {
      setLoading(false);
    }
  }, [order]);

  useEffect(() => {
    let timer;
    if (countdown > 0) {
      timer = setTimeout(() => {
        setCountdown(countdown - 1);
      }, 1000);
    } else if (countdown === 0 && invitationMessage) {
      getInvitation();
    }
    return () => clearTimeout(timer);
  }, [countdown, invitationMessage, getInvitation]);

  const handleUserChange = (e) => {
    setUser(e.target.value);
  };

  const toggleHelp = () => {
    setShowHelp(!showHelp);
  };

  const goToOrderPage = () => {
    window.open(contactInfo.orderUrl, '_blank');
  };

  // 修改退费处理函数
  const handleRefund = async () => {
    if (!order || refundLoading) return;
    
    try {
      setRefundLoading(true);
      // 隐藏订单信息和二维码
      setInvitation(null);
      setInvitationMessage('');
      setParsedLink(null);
      setQrCodeContent(null);
      
      // 生成8-15秒的随机等待时间
      const waitTime = Math.floor(Math.random() * (10000 - 6000 + 1) + 6000);
      await new Promise(resolve => setTimeout(resolve, waitTime));

      const formData = new FormData();
      formData.append('order_id', order.id);
      formData.append('task', order.user);

      const response = await fetch(`${API_BASE_URL}/refund`, {
        method: 'POST',
        body: formData,
      });

      await new Promise(resolve => setTimeout(resolve, 6000));

      const data = await response.json();
      
      if (data.code === 0) {
        alert('退费成功');
        window.location.reload();
      } else {
        alert(data.message || '退费失败');
      }
    } catch (error) {
      console.error('退费请求失败:', error);
      alert('退费失败，请稍后重试');
    } finally {
      setRefundLoading(false);
    }
  };

  return (
    <div className="App">
      <header className="header">
        <h1>链接获取系统</h1>
      </header>
      <main className="content">
        <div className="search-container">
          <input
            type="text"
            value={user}
            onChange={handleUserChange}
            placeholder="请输入用户ID"
          />
          <button onClick={() => getOrder(user)} disabled={loading}>
            {loading ? '查询中...' : '查询订单'}
          </button>
        </div>

        {orderMessage && <p className="message">{orderMessage}</p>}

        {order && (
          <div className="order-info">
            <h2>
              订单信息
              <span className="help-icon" onClick={toggleHelp}>?</span>
            </h2>
            <div className="order-details">
              <div className="order-item">
                <span className="order-label">用户ID:</span>
                <span className="order-value">
                  {order.user}
                  {order.user === '1234' && <span className="free-trial"> (免费试用)</span>}
                </span>
              </div>
              <div className="order-item">
                <span className="order-label">添加时间:</span>
                <span className="order-value">{order.addtime}</span>
              </div>
              <div className="order-item">
                <span className="order-label">剩余使用次数:</span>
                <span className="order-value highlight">{order.quantity}</span>
              </div>
              <div className="order-item">
                <span className="order-label">状态:</span>
                <span className={`order-value status ${order.status === 0 ? 'active' : 'inactive'}`}>
                  {order.status === 0 ? '有效' : '无效'}
                </span>
              </div>
            </div>
            <div className="order-actions">
              <button 
                onClick={getInvitation} 
                className="invitation-button" 
                disabled={loading || order.quantity <= 0}
              >
                {loading ? '获取中...' : 
                 (invitation && refundCountdown > 0) ? 
                 `获取邀请链接 (${refundCountdown}s)` : 
                 '获取邀请链接'}
              </button>
              {showRefund && (
                <div className="refund-section">
                  <button 
                    onClick={handleRefund}
                    className="refund-button"
                    disabled={loading || order.quantity <= 0 || refundLoading}
                  >
                    {refundLoading ? '等待退费确认...' : '退费'}
                  </button>
                  <p className="refund-tip">进队伍未成功可以退费一次</p>
                </div>
              )}
            </div>
          </div>
        )}

        {!order && orderMessage && contactInfo.orderUrl && (
          <div className="order-info">
            <button onClick={goToOrderPage} className="order-button highlight">
              前往下单页面
            </button>
          </div>
        )}

        {(invitation || invitationMessage) && (
          <div className="invitation-info">
            <h3>邀请链接信息</h3>
            {invitationMessage ? (
              <>
                <p className="invitation-message">{invitationMessage}</p>
                {countdown > 0 && (
                  <>
                    <p className="countdown-message">
                      {countdown}秒后自动重试 (第{retryCount}次尝试)
                    </p>
                    <p className="contact-info">
                      等待时间过久请联系管理员微信{contactInfo.contactMethod}
                    </p>
                  </>
                )}
              </>
            ) : (
              <>
                <div className="invitation-item">
                  <span className="invitation-label">队长球球号/ID:</span>
                  <span className="invitation-value">
                    {invitation.qqid}
                    {' / '}
                    <span className="captain-id">{invitation.name}</span>
                  </span>
                </div>
                <div className="invitation-item">
                  <span className="invitation-label">链接更新时间:</span>
                  <span className="invitation-value">{getTimeDifference(invitation.updatetime)}</span>
                </div>
                {!invitation.link.includes('http://www.battleofballs.com?id=') && (
                  <div className="invitation-item">
                    <span className="invitation-label">原始链接:</span>
                    <a href={invitation.link} target="_blank" rel="noopener noreferrer" className="invitation-link">
                      {invitation.link}
                    </a>
                  </div>
                )}
                {parsedLink && (
                  <div className="invitation-item">
                    <button 
                      onClick={() => window.location.href = parsedLink} 
                      className="parsed-link-button"
                    >
                      点击加入队伍
                    </button>
                  </div>
                )}
                {qrCodeContent && (
                  <div className="qr-code-container">
                    <p className="qr-code-instruction">点按钮无法跳转请扫码进队伍</p>
                    <div className="qr-code-wrapper">
                      <QRCodeSVG value={qrCodeContent} size={128} />
                      <div className="qr-code-text">扫码加入队伍</div>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        )}

        {showHelp && (
          <div className="help-overlay" onClick={toggleHelp}>
            <div className="help-content" onClick={(e) => e.stopPropagation()}>
              <h3>使用帮助 <span className="close-icon" onClick={toggleHelp}>&times;</span></h3>
              <ul>
                <li>剩余次数为零时，请重新购买。</li>
                <li>使用次数仅在当天有效。</li>
                <li>加入队伍后，再次点击获取将会扣除次数。</li>
                <li>获取链接超过5分钟后，链接可能会失效。</li>
                <li>链接失效后重新获取将会扣除次数。</li>
                <li>建议在准备好进行游戏后再获取链接，以避免不必要的次数消耗。</li>
                <li>进队伍未成功可以退费一次，退费后将恢复使用次数。</li>
                <li>退费功能需要等待15秒左右才会显示结果。</li>
              </ul>
            </div>
          </div>
        )}
      </main>

      <footer className="footer">
        <p>© 2024 订单查询系统. 保留所有权利.</p>
      </footer>
    </div>
  );
}

export default App;
